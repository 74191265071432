import styled from "styled-components";
import tw from "twin.macro";
import { motion } from "framer-motion";

//CONTAINER REUSABLES

export const PageContainer = styled(motion.div)`
    ${tw`
        bg-main
        relative
        min-h-screen
        w-full
        h-full
        overflow-hidden
    `};

    &.world {
        ${tw`
        bg-main
        `};
    }
`;

export const MainWrapper = styled(motion.div)`
    ${tw`
        w-[80%]
        flex
        flex-row
        justify-center
        items-start
        max-w-[1440px]
    `};
`;

//NEUMORPHIC & GLASS CONTAINERS

export const GlassCard = styled(motion.div)`
    ${tw`
        relative
        flex
        flex-col
        w-full
        h-full
        p-8
        md:p-12
        text-white
        `};

    /* background: linear-gradient(
    110.72deg,
        rgba(255, 255, 255, 0.088) 6.19%,
        rgba(255, 255, 255, 0.018) 80.27%
    ); */
    /* background: linear-gradient(
        110.72deg,
        rgba(121, 139, 255, 0.088) 6.19%,
        rgba(253, 13, 169, 0.018) 80.27%
    ); */
    background: linear-gradient(
        110.72deg,
        rgba(46, 74, 255, 0.088) 6.19%,
        rgba(247, 14, 255, 0.02) 80.27%
    );
    background-blend-mode: overlay;
    box-shadow: 10px 0px 50px 11px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(20px);
    border-top: 1px solid #f0e7f5ca;
    border-bottom: 1px solid #bf9ad34d;
    border-radius: 40px;
    z-index: 15;

    & h1 {
        ${tw`
            text-2xl
        `};
        letter-spacing: 5px;
        z-index: 100;
    }
    & h2 {
        letter-spacing: 3px;
        z-index: 100;
    }

    &.quests {
    }

    &.perks {
    }

    &.inventory-item {
        background: linear-gradient(
            110.72deg,
            rgba(21, 18, 130, 0.052) 6.19%,
            rgba(247, 12, 255, 0.068) 80.27%
        );
        background-blend-mode: overlay;
        box-shadow: 10px 0px 50px 11px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(55px);
        border-bottom: 1px solid #bf9ad34d;
        border-radius: 40px;
        border-top: 1px solid #e3d8ff4a;
    }
`;

export const GlassBackdrop = styled(motion.div)`
    ${tw`
        absolute
        bottom-[4%]
        left-[4%]
        w-full
        h-full
    `};

    background: linear-gradient(
        100.57deg,
        rgba(134, 69, 255, 0.577) 1.47%,
        rgba(255, 25, 174, 0.34) 98.39%
    );
    box-shadow: 10px 0px 50px 11px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(2px);
    border-radius: 40px;

    &.quests {
    }

    &.perks {
    }

    &.inventory-item {
        ${tw`
            absolute
            bottom-[15%]
            left-[15%]
            w-[70%]
            h-[70%]
        `};
        margin: 0 auto;
        background: linear-gradient(
            110.72deg,
            rgba(46, 210, 255, 0.669) 6.19%,
            rgba(255, 14, 223, 0.359) 80.27%
        );
    }
`;

export const NeuCard = styled(motion.div)`
    ${tw`
        
        flex
        flex-col
        p-12
        
        text-white
    `};
    width: clamp(95%, 500px, 90%);

    background: #23202a;
    box-shadow: 1px 1px 2px rgba(47, 43, 56, 0.3),
        -1px -1px 2px rgba(23, 21, 28, 0.5),
        inset -13px 13px 26px rgba(23, 21, 28, 0.2),
        inset 13px -13px 26px rgba(23, 21, 28, 0.2),
        inset -13px -13px 15px rgba(47, 43, 56, 0.5),
        inset 13px 13px 33px rgba(23, 21, 28, 0.6);
    border-radius: 12px;

    & h1 {
        ${tw`
            text-2xl
        `};
        letter-spacing: 5px;
        z-index: 100;
    }
    & h2 {
        letter-spacing: 3px;
        z-index: 100;
    }
`;

export const GlassTitle = styled.div`
    ${tw`
        flex
        justify-center
        items-center
        p-5
        
    `};

    background: linear-gradient(
        110.72deg,
        rgba(203, 17, 255, 0.093) 6.19%,
        rgba(29, 67, 255, 0.069) 90.27%
    );
    background-blend-mode: overlay;
    box-shadow: 0px 1.1966px 11px rgba(69, 42, 124, 0.1);
    backdrop-filter: blur(22px);
    border-bottom: 1px solid #c0c0c027;
    border-top: 1px solid #c0c0c01d;
    box-sizing: border-box;
`;
