import React, { Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import { AnimatePresence } from "framer-motion";

import Loader from "./Loader/Loader";
import LoaderWorld from "./Loader/LoaderWorld";
import GalleryImage from "./GalleryImage";

const HomePage = React.lazy(() => import("pages/HomePage"));
const AboutPage = React.lazy(() => import("pages/AboutPage"));
const InventoryPage = React.lazy(() => import("pages/InventoryPage"));
const ExperiencePage = React.lazy(() => import("pages/ExperiencePage"));
const GalleryPage = React.lazy(() => import("pages/GalleryPage"));

const World3DPage = React.lazy(() => import("pages/World3DPage"));

const AnimatedRoutes = () => {
    const location = useLocation();

    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route
                    path="/"
                    element={
                        <Suspense fallback={<Loader />}>
                            <HomePage />
                        </Suspense>
                    }
                />
                <Route
                    path="/about"
                    element={
                        <Suspense fallback={<Loader />}>
                            <AboutPage />
                        </Suspense>
                    }
                />
                <Route
                    path="/inventory"
                    element={
                        <Suspense fallback={<Loader />}>
                            <InventoryPage />
                        </Suspense>
                    }
                />
                <Route
                    path="/experience"
                    element={
                        <Suspense fallback={<Loader />}>
                            <ExperiencePage />
                        </Suspense>
                    }
                />
                <Route
                    path="/world"
                    element={
                        <Suspense fallback={<LoaderWorld />}>
                            <World3DPage />
                        </Suspense>
                    }
                />
                <Route
                    path="/gallery"
                    element={
                        <Suspense fallback={<LoaderWorld />}>
                            <GalleryPage />
                        </Suspense>
                    }
                />

                <Route
                    path="/gallery/:imageId"
                    element={
                        <Suspense fallback={<LoaderWorld />}>
                            <GalleryImage />
                        </Suspense>
                    }
                />
            </Routes>
        </AnimatePresence>
    );
};

export default AnimatedRoutes;
