import React from "react";

import { PageContainer } from "components/globals/Globals";
import { loadingTransition } from "animation/variants";

import "./Loading.scss";

interface LoaderType {
    setLoading: boolean;
}

const Loader = () => {
    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         setLoading(false);
    //     }, 1500);
    //     return () => clearTimeout(timer);
    // });

    return (
        <>
            <PageContainer
                className="content"
                variants={loadingTransition}
                initial="hidden"
                animate="show"
                exit="exit"
            >
                <div className="flex flex-col justify-center items-center h-screen space-y-10 p-8 text-center">
                    <h1 className="text-white md:text-xl text-lg loading">
                        downloading world mesh
                        <span className="ellipsis-anim">
                            <span>.</span>
                            <span>.</span>
                            <span>.</span>
                        </span>
                    </h1>
                    <div className="sbl-circ-dual"></div>
                </div>
            </PageContainer>
        </>
    );
};

export default Loader;
