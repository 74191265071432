import React from "react";

import { PageContainer } from "components/globals/Globals";
import { loadingTransition } from "animation/variants";
import { Html, useProgress } from "@react-three/drei";
import "./Loading.scss";

interface LoaderType {
    setLoading: boolean;
}

const LoaderWorld = () => {
    const { progress }: any = useProgress();

    return (
        <>
            <PageContainer
                className="content"
                variants={loadingTransition}
                initial="hidden"
                animate="show"
                exit="exit"
            >
                <div className="flex flex-col justify-center items-center h-screen space-y-10 p-8 text-center">
                    <h1 className="text-white md:text-xl text-lg loading">
                        downloading divine architecture
                        <br />
                        <br />
                        <span className="tracking-[8px] text-pink">
                            {progress.toFixed(1)}%
                        </span>
                        <span className="ellipsis-anim">
                            <span className="text-pink">.</span>
                            <span className="text-pink">.</span>
                            <span className="text-pink">.</span>
                        </span>
                    </h1>
                    <div className="sbl-circ-dual"></div>
                </div>
            </PageContainer>
        </>
    );
};

export default LoaderWorld;
