//HOME ELEMENTS ANIMATIONS
export const logoAnimation: any = {
    hidden: {
        opacity: 0,
        x: -200,
    },
    show: {
        opacity: 1,
        x: 0,
        transition: {
            ease: [0.6, 0.01, -0.05, 0.95],
            duration: 1.6,
        },
    },
    exit: {
        opacity: 0,
        x: -200,
        transition: {
            ease: "easeInOut",
            duration: 0.8,
        },
    },
};

export const imageAnimation: any = {
    hidden: {
        opacity: 0,
        y: 200,
    },
    show: {
        opacity: 1,
        y: 0,
        transition: {
            ease: [0.6, 0.01, -0.05, 0.95],
            duration: 1.4,
            delay: 0.4,
        },
    },
    exit: {
        opacity: 0,
        x: -200,
        transition: {
            ease: "easeInOut",
            duration: 0.8,
        },
    },
};

export const navAnimation: any = {
    hidden: {
        opacity: 0,
        y: -200,
    },
    show: {
        opacity: 1,
        y: 0,
        transition: {
            ease: [0.6, 0.01, -0.05, 0.95],
            duration: 1.4,
            delay: 0.8,
        },
    },
    exit: {
        opacity: 0,
        y: -200,
        transition: {
            ease: "easeInOut",
            duration: 0.8,
        },
    },
};

//PAGE ANIMATIONS
export const pageTransition: any = {
    hidden: {
        opacity: 0,
        x: 200,
    },
    show: {
        opacity: 1,
        x: 0,
        transition: {
            ease: [0.6, 0.01, -0.05, 0.95],
            duration: 1.6,
        },
    },
    exit: {
        opacity: 0,
        x: -200,
        transition: {
            ease: "easeInOut",
            duration: 0.8,
        },
    },
};

export const loadingTransition: any = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 1,

        transition: {
            ease: [0.6, 0.01, -0.05, 0.95],
            duration: 0.5,
        },
    },
    exit: {
        opacity: 0,

        transition: {
            ease: "easeInOut",
            duration: 0.8,
        },
    },
};

export const worldTransition: any = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 1,

        transition: {
            ease: "easeInOut",
            duration: 1.5,
        },
    },
    exit: {
        opacity: 0,
        y: 200,
        transition: {
            ease: "easeInOut",
            duration: 0.8,
        },
    },
};

export const inventoryTransition: any = {
    hidden: {
        x: 0,
    },
    show: {
        x: 0,
        transition: {
            ease: [0.6, 0.01, -0.05, 0.95],
            duration: 1.5,
        },
    },
    exit: {
        y: 800,
        transition: {
            ease: "easeInOut",
            duration: 0.8,
        },
    },
};

// 3D WORLD
export const textTransition: any = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 1,
    },
    exit: {
        opacity: 0,

        transition: {
            ease: "easeInOut",
            duration: 0.8,
        },
    },
};

//CIRCLE ANIMATIONS
export const circleTransition: any = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 0.7,
        transition: {
            delay: 1,
            duration: 0.8,
        },
    },
    exit: {
        opacity: 0,

        transition: {
            ease: "easeInOut",
            duration: 0.8,
        },
    },
};

//MODAL ANIMATIONS
export const overlayTransition: any = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 1,

        transition: {
            ease: "easeInOut",
            duration: 0.5,
        },
    },
    exit: {
        opacity: 0,
        transition: {
            ease: "easeInOut",
            duration: 0.8,
        },
    },
};

export const modalTransition: any = {
    hidden: {
        scale: 0,
        opacity: 0,
    },
    show: {
        scale: 1,
        opacity: 1,

        transition: {
            ease: "easeInOut",
            duration: 0.5,
        },
    },
    exit: {
        scale: 0,
        transition: {
            ease: "easeInOut",
            duration: 0.8,
        },
    },
};
export const galleryTransition: any = {
    hidden: {
        opacity: 0,
        transition: {
            ease: "easeInOut",
            duration: 1.5,
        },
    },
    show: {
        opacity: 1,
        transition: {
            ease: "easeInOut",
            duration: 1.5,
        },
    },
    exit: {
        opacity: 0,
        transition: {
            ease: "easeInOut",
            duration: 1,
        },
    },
};
